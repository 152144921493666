<template>
  <div id="cart">
    <v-container>
      <div class="d-flex align-center my-4">
        <v-btn
            fab
            x-small
            elevation="0"
            @click="$router.go(-1)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="text-h6 px-2">Carrito</div>
      </div>
      <cart-item @change="updateQuantity" v-for="item in items" :key="item.id" :item="item" ></cart-item>

    </v-container>
    <v-container>
      <v-btn tile depressed x-large class="bottom-btn green lighten-1 white--text" @click="goTo" :disabled="cart===0">
        <div class="text-capitalize">Continuar compra</div>
        <div class="text-uppercase text-right">€ {{cart | money}}</div>
      </v-btn>
    </v-container>
  </div>

</template>

<script>
import CartItem from "../../../components/restaurant/CartItem";
import {Restaurant} from "@/services/api";
export default {
  name: "Cart",
 components: {CartItem},
  mounted() {
    this.$store.commit('setLoading', false)
  },
  methods:{
    requestRestaurant(){
      return Restaurant(this.user.token_type, this.user.access_token)
    },
      updateQuantity(data){
      const restoRequest = this.requestRestaurant()
      restoRequest.updateArticle(data.id, data.quantity).then(
          () => restoRequest.getOrder().then(
              data => this.$store.commit('setOrder', data.data.data)
          )
      )

    },
    goTo(){
      this.cart - this.$store.state.order.available_discount > 0 ? this.$router.push({name: 'MobileCartPayment'}) : this.$router.push({name: 'MobileCartDetails'})
    }
  },
  computed:{
    items(){
      return this.$store.state.order.items
    },
    cart(){
      const items = this.$store.state.order ? this.$store.state.order.items : [];
      let price = 0;
      items.forEach(item =>{
        if(!item.item_related_id){
          price += item.quantity * item.unit_price
        }

      })
      return price
    },
    user(){
      return this.$store.state.user || {}
    },
  }
}
</script>

<style lang="scss">
#cart {
  .bottom-btn {
    position: fixed;
    bottom: 28px;
    width: 93%;

    .v-btn__content {
      justify-content: space-between;
    }
  }
}
</style>